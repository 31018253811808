import React, { useState, useRef, useEffect, useContext } from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
//import PubSub from 'pubsub-js';
import SEO from "../components/seo"
import Img from "gatsby-image"
import { FiltersContext } from "../components/Layout"

export const pageQuery = graphql`
  query About {
    about: prismicAbout {
      data {
        titre {
          text
        }
        description {
          text
        }
        tabs {
          title {
            text
          }
          texte {
            html
          }
          detail {
            html
          }
          image {
            url
            alt
            localFile {
              ...sharp
            }
          }
        }
        contact {
          html
        }
      }
    }
  }
`

const PageAbout = props => {
  const tabHeader = useRef()
  const [tab, setTab] = useState(0)
  const _FiltersContext = useContext(FiltersContext)
  const { setFilters } = _FiltersContext

  const { titre, description, tabs, contact } = props.data.about.data
  // console.log(tab)

  useEffect(() => {
    _resetFilters()
    _handleAgencyCoverOffset()
  }, [])

  const _resetFilters = () => {
    setFilters({
      program: [],
      year: [],
      location: [],
    })
  }

  const _handleAgencyCoverOffset = () => {
    // if(window.innerWidth < 1200)return
    const tabHeaderHeight =
      tabHeader.current.getBoundingClientRect().height + 12
    const agencyCover = document.querySelector(".content:first-child figure")
    if(agencyCover)
      agencyCover.style.transform = `translateY(-${tabHeaderHeight}px)`
    // console.log(agencyCover)
  }

  return (
    <div className="about">
      <SEO
        pageTitle={titre.text}
        pageDescription={description.text}
        page={true}
        template="template-about"
      />
      <div className="cont">
        <div className="row">
          <div className="col-md-5 col-xs-10 col-about">
            <div className="tabs">
              <div className="tabs-header x" ref={tabHeader}>
                {tabs.map((item, i) => (
                  <h2
                    className={"header fXL " + (tab === i ? "is-active" : "")}
                    key={i}
                    onClick={() => setTab(i)}
                  >
                    {item.title.text}
                  </h2>
                ))}
              </div>
              <div className="tabs-content">
                {tabs.map((item, i) => (
                  <div
                    className={"content " + (tab === i ? "is-active" : "")}
                    key={i}
                  >
                    <div className="row">
                      {item.texte.html && (
                        <div className="col-xs">
                          <div
                            className="texte"
                            dangerouslySetInnerHTML={{
                              __html: item.texte.html,
                            }}
                          ></div>
                        </div>
                      )}
                      
                      <div className="col-xs">
                        {item.image && item.image.localFile && 
                          <figure>
                            <Img
                              fluid={item.image.localFile.childImageSharp.fluid}
                            />
                            <figcaption
                              dangerouslySetInnerHTML={{
                                __html: item.detail.html,
                              }}
                            ></figcaption>
                          </figure>
                        }
                      </div>
                      
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="col-md-5 col-xs-10 col-contact">
            <div className="x xje">
              <div className="contact">
                <div
                  className=" fXL fCond"
                  dangerouslySetInnerHTML={{ __html: contact.html }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageAbout

